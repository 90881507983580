"use client";

import { useEffect } from "react";
import { GoogleAnalyticsId } from "../constants/services";
import { sendGoogleConversion } from "../services/conversion/Google";

// @see https://developers.google.com/analytics/devguides/collection/gtagjs/pages#default_behavior
interface Config {
  pageTitle?: string;
  pageLocation?: string;
  pagePath?: string;
  sendPageView?: boolean;
}
export function usePageView(config?: Config) {
  const { pageTitle, pageLocation, pagePath, sendPageView } = config ?? {};

  useEffect(() => {
    sendGoogleConversion("event", "page_view", {
      page_title: pageTitle,
      page_location: pageLocation,
      page_path: pagePath,
    });
    if (!GoogleAnalyticsId) return;
    sendGoogleConversion("config", GoogleAnalyticsId, {
      page_title: pageTitle,
      page_location: pageLocation,
      page_path: pagePath,
      send_page_view: sendPageView,
    });
  }, [pageTitle, pageLocation, pagePath, sendPageView]);

  return null;
}
