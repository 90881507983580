"use client";

import { Trans } from "react-i18next/TransWithoutContext";
import { useClientTranslation } from "@rimo/i18n/useClientTranslation";
import React, { useContext } from "react";
import styled from "styled-components";

import { usePageView } from "../hooks/useGtag";
import { color } from "../styles/color";
import { SimpleLayout } from "../components/layouts/SimpleLayout";
import { UserContext } from "../contexts/UserContext";

export const NotFound: React.FC = () => {
  const { customT } = useClientTranslation();
  usePageView({ pageTitle: "Not Found" });
  const { userState } = useContext(UserContext);

  return (
    <SimpleLayout themeEnabled={true}>
      <Container>
        <Img src="/assets/not_found/img.png" />
        <StatusCode>404</StatusCode>
        <Lead>{customT("ページが見つかりませんでした")}</Lead>
        {/* <Desc></Desc> */}
        {!userState.user && <LoginButton href="/login">{customT("ログイン")}</LoginButton>}
        <Links>
          <Trans
            i18nKey="<Link href='https://twitter.com/RimoApp'>Twitter</Link> <Link href='https://rimo.app/join-slack '>Slackコミュニティ</Link> <Link href='https://rimo.app/contact'>お問い合わせ</Link>"
            components={{ Link: <Link /> }}
          />
        </Links>
      </Container>
    </SimpleLayout>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -64px;
  background: ${(p) => p.theme.vars.palette.background.experimental.layeredDefault};
`;
const Img = styled.img`
  width: 337px;
`;

const StatusCode = styled.h1`
  font-size: 50px;
  line-height: 59px;
  color: ${color.primary};
  margin-bottom: 10px;
`;

const Lead = styled.h2`
  font-size: 24px;
  line-height: 28px;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;

// const Desc = styled.p`
//   font-size: 16px;
//   line-height: 19px;
//   margin-bottom: 16px;
// `;

const LoginButton = styled.a`
  padding: 12px 32px;
  background: ${color.primary};
  color: ${(p) => p.theme.vars.palette.text.experimental.onPrimary};
  border-radius: 4px;
  margin-top: 30px;
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const Link = styled.a`
  font-size: 14px;
  line-height: 16px;
  color: ${(p) => p.theme.vars.palette.text.secondary};
  margin: 0 15px;
`;
